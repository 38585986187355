.Me {
  height: 32vmin;
  width: 32vmin;
  pointer-events: none;
  border-radius: 50%;
  padding: 0.75rem;
  background: linear-gradient(135deg,#b30369,#ff9422);
}

.keyword {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(-45deg,#b30369,#ff9422);
  -webkit-background-clip: text;
}

.picture {
  padding-right: 24px;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.description {
  padding-left: 24px;
  width: 50%;
}

.big-title {
  font-size: 48px;
  font-weight: 700;
  margin-block-start: 0.55em;
}

.title {
  font-size: 32px;
  font-weight: 700;
}

.nav {
  width: 100vw;
  height: 48px;
  position: absolute;
  font-size: 18px;
  font-weight: 700;
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.nav-item {
  margin: 3px 20px 0px 20px;
  padding: 2px;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-image-slice: 1;
  border-image-source: linear-gradient(45deg,#b30369,#ff9422);
  box-sizing: border-box;
  height: 32px;
  text-decoration: none;
  color: inherit;
  transition: border-bottom-width 0.1s ease-out;
}

.nav-item-selected {
  border-bottom-width: 5px;
}

.about {
  display: flex;
  flex-direction: row;
}

.about-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section {
  padding-top: 120px;
  max-width: 1200px;
  margin: auto;
}

.section-container {
}

.section-description {
  padding: 30px;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.item-picture {
  width: 50%;
  padding: 30px;
  flex-grow: 1;
}

.picture-frame {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.item-picture-img-border {
  width: calc(100% - 6px);
  padding: 3px;
  background: linear-gradient(135deg,#b30369,#ff9422);
  position: absolute;
  border-radius: 4px;
}

.item-picture-img {
  width: calc(100% - 6px);
  position: absolute;
  border-radius: 4px;
  border: 3px solid white;
}

.hover {
  opacity: 0%;
  transition: opacity 0.1s ease-out;
}

.hover:hover {
  opacity: 100%;
}

.item-desc {
  width: 50%;
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-grow: 1;
}

.Xme {
  font-family: 'Fira Sans';
  color: white;
  font-weight: 400;
  font-size: 24px;
}

.content {
  width: 100vw;
  height: 100vh;
}

.item-link {
  text-decoration: item-link;
  text-decoration-color: white;
  color: inherit;
}

.item-title {
}

.social {
  width: 24px;
  /* border: 1px solid #000000; */
  filter: grayscale(1) brightness(0) invert(1);
  height: 24px;
  border-radius: 3px;
  padding: 6px;
}

li {
  padding: 4px;
}

li::marker {
  color: white;
  content: "+    ";
  font-family: Times New Roman;
  font-weight: bold;
}


@media only screen and (max-width: 600px) {
  .nav-item {
    margin: 3px 10px 0px 10px;
  }
  .about, .item {
    flex-direction: column;
  }
  .item-picture, .item-desc  {
    width: calc(100% - 60px);
  }
  .picture {
    padding-top: 72px;
    padding-bottom: 36px;
    padding-right: inherit;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .description {
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 80px);
  }
  .Me {
    height: 64vmin;
    width: 64vmin;
  }
  .big-title {
    font-size: 1.8em;
  }
  .title {
    font-size: 1.2em;
  }
}
